<template>
    <div class="content" :class="$style.isFinish">
        <h1 class="pagetitle">
            <template v-if="name === 'user'">
                {{ management[name].title }}{{ textset[editMode] }}：完了
            </template>
            <template v-else-if="name === 'order'">
                {{ management[name].title }}完了
            </template>
        </h1>
        <div>
            <template v-if="name === 'user'">
                <p>
                    {{ textset[editMode] }}が完了しました！
                </p>
            </template>
            <template v-else-if="name === 'order'">
                <p>
                    {{ management[name].title }}が完了しました！
                </p>
            </template>
        </div>
    </div>
</template>

<script>
    "use strict";

    import management from "@/settings/management.js";
    import textset from "@/settings/textset.js";

    export default {
        "props": {
            "editMode": {
                "type": String,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "management": management,
                "textset": textset[management[this.name].textset],
            };
        },
    };
</script>

<style lang="scss" module>
    .isFinish {
        div {
            padding: 32px 0;
        }
    }
</style>
